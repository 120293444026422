import { Button, Descriptions, Modal, Table } from 'antd';
import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { Loading } from '../../components/Loading';
import { useEffect } from 'react';
import { Socket_io } from '../../utis/socket';
import { useRef } from 'react';

function Test1() {
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [socketResponse, setSocketResponse] = useState(true);
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const textareaRefs = useRef({});

  const [showResult, setShowResult] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1000,
  });

  const [rowData, setRowData] = useState({});

  const resultItems = Object.keys(rowData).map((key, index) => ({
    key: (index + 1).toString(), // 임의의 key 값으로 index를 사용
    label: key,
    children: <span>{rowData[key]}</span>,
  }));

  const editItems = Object.keys(rowData).map((key, index) => ({
    key: (index + 1).toString(),
    label: key,
    children: (
      <textarea
        name={key}
        ref={(el) => {
          textareaRefs.current[key] = el;
        }}
        className='input-style auto-adjust-height'
        value={rowData[key] || ''}
        onChange={(e) => handleInputChange(e, key)}
        rows={1} // 최소 줄 수
        onClick={(e) => autoAdjustHeight(e)}
        style={{
          height: 'auto', // 최대 높이 설정, 이 값을 초과하면 스크롤바가 생김
          overflowY: 'auto', // 세로 스크롤바는 자동으로 표시
        }}
      />
    ),
  }));

  console.log('textareaRefs', textareaRefs);

  function autoAdjustHeight(e) {
    const textarea = e.target;
    textarea.style.height = 'auto'; // 먼저 높이를 auto로 설정하여 크기를 초기화합니다.
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleSubmit = async (e) => {
    // e.preventDefault(); // 기본 제출 이벤트 방지

    try {
      // 서버로 POST 요청 보내기
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/notices/modify`,
        formData
      );
      console.log(response.data);
      // 성공적으로 데이터를 전송한 후의 로직 (예: 모달 닫기, 상태 초기화 등)
      setEditing(false);
      fetchData();
    } catch (error) {
      console.error('서버로 데이터를 전송하는데 실패했습니다.', error);
    }
  };

  const handleInputChange = (e, key) => {
    const textarea = e.target;
    textarea.style.height = 'auto'; // 높이를 초기화
    textarea.style.height = `${textarea.scrollHeight}px`;
    const updatedValue = e.target.value;
    const textareaById = document.querySelector('textarea[name="id"]');
    // rowData 상태 업데이트
    setRowData((prevRowData) => ({
      ...prevRowData,
      [key]: updatedValue,
    }));

    // formData 상태 업데이트
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: updatedValue,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [textareaById.name]: textareaById.value,
    }));
  };

  const inputChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const [open, setOpen] = useState(false);

  const columns = [
    {
      width: 100,
      title: '번호',
      dataIndex: 'id',
      ellipsis: true,
    },
    {
      title: '타이틀',
      dataIndex: 'title',
      render: (text) => {
        return (
          <span
            className='text-blue-500 transition duration-300 cursor-pointer hover:text-blue-300 bold'
            onClick={() => {
              setOpen(true);
            }}>
            {text}
          </span>
        );
      },
      ellipsis: true,
    },
    {
      title: '공고일',
      dataIndex: 'postedDate',
      render: (text) => {
        const dateOnlyText = text.replace(
          /\s\d{2}:\d{2}:\d{2}(.\d{1,3})?-\d{2}/,
          ''
        );
        return <span>{dateOnlyText}</span>;
      },
    },
  ];

  useEffect(() => {
    // 초기 데이터 로드
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/notices/sort/${pagination.current}`
        );
        console.log(response);
        const result = await response.json();
        console.log(result);
        // 데이터와 페이징 정보 업데이트
        setData(result.data); // 예시로, 서버 응답에 'data' 필드가 있다고 가정합니다
        setPagination({
          ...pagination,
          total: result.total, // 예시로, 서버 응답에 'total' 필드가 있다고 가정합니다
        });
      } catch (error) {
        console.error('Fetch data error:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // 모든 textarea 요소에 대해서 높이 조절 로직을 실행합니다.
    if (Object.keys(textareaRefs.current).lengt > 0) {
      Object.keys(textareaRefs.current).forEach((key) => {
        const textarea = textareaRefs.current[key];
        // 높이를 재설정합니다.
        textarea.style.height = 'auto';
        // 콘텐츠 높이만큼 높이를 설정합니다.
        textarea.style.height = textarea?.scrollHeight + 'px';
      });
    }
  }, [rowData]); // rowData가 변경될 때마다 이 코드를 실행합니다.

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/notices/sort/${pagination.current}`
      );
      // console.log(response)
      const result = await response.json();
      // console.log(typeof result.total)
      // console.log(result.data)
      // 데이터와 페이징 정보 업데이트
      setData(result.data); // 예시로, 서버 응답에 'data' 필드가 있다고 가정합니다
      setPagination({
        ...pagination,
        total: result.total, // 예시로, 서버 응답에 'total' 필드가 있다고 가정합니다
      });
    } catch (error) {
      console.error('Fetch data error:', error);
    }
  };

  useEffect(() => {
    fetchData(pagination.current);
  }, [pagination.current]);

  return (
    <div className='flex items-center justify-center h-full gap-10 mx-5'>
      <div className='flex items-center justify-center w-full h-full shadow-xl rounded-2xl bg-slate-50'>
        <div className='flex flex-col items-start'>
        <div className='w-full px-5 py-3 text-xl font-bold'>
            총 개수: {pagination.total}개
        </div>
          <Table
            className='w-full'
            columns={data.length === 0 ? [] : columns}
            dataSource={data}
            pagination={pagination}
            onChange={handleTableChange}
            align='center'
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  setRowData(record);
                }, // click row
              };
            }}
          />
        </div>

        <form onSubmit={inputChange}>
          <Modal
            title={
              <div
                className='flex items-center justify-between'
                style={{ position: 'relative' }}>
                {' '}
                {/* 부모 요소에 relative 포지셔닝 추가 */}
                <span>매칭 결과</span>
                {editing ? (
                  <Button
                    style={{
                      position: 'absolute', // 절대적 위치 설정
                      top: '-34.5%', // 버튼을 상단으로 5px 이동
                      left: '95%',
                    }}
                    onClick={() => {
                      handleSubmit();
                      setEditing(false);
                    }}>
                    완료
                  </Button>
                ) : (
                  <Button
                    style={{
                      position: 'absolute', // 절대적 위치 설정
                      top: '-34.5%', // 버튼을 상단으로 5px 이동
                      left: '95%',
                    }}
                    onClick={() => setEditing(true)}>
                    수정
                  </Button>
                )}
              </div>
            }
            centered
            open={open}
            onOk={() => {
              setOpen(false);
              setEditing(false);
            }}
            onCancel={() => {
              setOpen(false);
              setEditing(false);
            }}
            width={'95%'}
            height={'90%'}
            className='overflow-auto'
            footer={null}>
            {editing && (
              <Descriptions
                bordered
                size='small'
                column={1}
                className='custom-descriptions'>
                {editItems.map((item) => (
                  <Descriptions.Item
                    key={item.key}
                    label={item.label}
                    labelStyle={{ width: '15%' }} // 라벨 영역의 너비를 설정
                    contentStyle={{ width: '85%' }} // 컨텐츠 영역의 너비를 설정
                  >
                    {item.children}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            )}
            {!editing && (
              <Descriptions
                bordered
                size='small'
                labelStyle={{ width: '15%' }} // 라벨 영역의 너비를 설정
                column={1}
                items={resultItems}
              />
            )}
          </Modal>
        </form>
      </div>
    </div>
  );
}

export default Test1;
