import React from 'react';
import { Layout as AntdLayout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

function Layout(props) {
  const { children } = props;
  const { Header } = AntdLayout;
  const navigate = useNavigate();

  const items = [
    // {
    //   key: 1,
    //   label: 'EC-PLAZA',
    // },
    // {
    //   key: 2,
    //   label: 'Chart',
    // },
    // {
    //   key: 3,
    //   label: "입찰문서 당 키워드 추출속도 테스트",
    // },
  ];

  return (
    <div className='flex flex-col h-screen'>
      
        <Menu
          theme='dark'
          className='text- white'
          mode='horizontal'
          defaultSelectedKeys={['1']}
          items={items.map((item) => {
            return {
              item: item.key,
              label: item.label,
            };
          })}
          onClick={(e) => {
            console.log(e.key, 'e.key');
            if (e.key === 'tmp-0') {
              navigate('/');
            } else if (e.key === 'tmp-1') {
              navigate(`/chart`);
            }
          }}
        />
      
      <div className='flex-col items-center justify-center flex-1 h-full '>
        <div className='w-full bg-white  min-h-[600px] h-full'>{children}</div>
      </div>
    </div>
  );
}

export default Layout;
