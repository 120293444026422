import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './Root';

import Test1 from '../Pages/Test1/Test1';
import { Chart } from '../Pages/Chart';

function Router() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <Root />,
      children: [
        { index: true, element: <Test1 /> },
        { path: '/', element: <Test1 /> },
        { path: '/chart', element: <Chart /> },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}

export default Router;
