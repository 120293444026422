import React, { useEffect, useState } from 'react';

import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  BarChart,
  Bar,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';

import { Button, Select, Radio, DatePicker } from 'antd';
import axios from 'axios';

const RADIO_OPTIONS = [
  { label: '연도별 총 데이터', value: '연도별 총 데이터' },
  { label: '부서간 연도별 데이터', value: '부서간 연도별 데이터' },
  { label: '부서간 월별 데이터', value: '부서간 월별 데이터' },
];

export const Chart = () => {
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/dsData/deptName`)
      .then((response) => {
        // 변환할 데이터 배열
        const dataArray = response.data;

        // 배열 요소를 변환하여 새로운 배열 생성
        const transformedDataArray = dataArray.map((item) => ({
          value: item.department, // 소문자로 변환하여 value에 할당
          label: item.department,
        }));

        setDepartmentList(transformedDataArray); // 변환된 데이터 배열 로깅
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const 연도별총데이터데이터가져오기 = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/dsData/yearDataAmount`)
      .then((res) => setChartData(res.data));
  };

  const 부서간월별데이터가져오기 = (year, department) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/dsData/yearMonthlyDept?year=${year}&dept=${department}`
      )
      .then((res) => setChartData(res.data));
  };
  const 부서간연도별데이터가져오기 = (department) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/dsData/yearlyDeptData?dept=${department}`
      )
      .then((res) => setChartData(res.data));
  };

  const 차트만들기 = (year, department) => {
    if (radioOptionValue === '연도별 총 데이터') {
      연도별총데이터데이터가져오기();
    }
    if (radioOptionValue === '부서간 월별 데이터') {
      부서간월별데이터가져오기(year, department);
    }
    if (radioOptionValue === '부서간 연도별 데이터') {
      부서간연도별데이터가져오기(department);
    }
  };

  const [departmentList, setDepartmentList] = useState([]);
  const [department, setDepartment] = useState('');

  const [year, setYear] = useState('');

  const [radioOptionValue, setRadioOptionValue] = useState('');
  const [chartData, setChartData] = useState([]);

  const [chartResultInfo, setChartResultInfo] = useState({
    label: '',
    chartTitle: '',
  });

  const radioOnChange = ({ target: { value } }) => {
    setRadioOptionValue(value);
    setYear('');
  };

  const selectOnChange = (value) => {
    setDepartment(value);
  };
  const selectOnSearch = (value) => {
    setDepartment(value);
  };

  const datePickerOnChange = (date, dateString) => {
    setYear(dateString);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const 차트만들기Disabled = () => {
    if (radioOptionValue === '부서간 월별 데이터') {
      return !(year.length && department.length);
    }
    if (radioOptionValue === '부서간 연도별 데이터') {
      return !department.length;
    }
  };

  const formatXAxis = (tickItem) => {
    if (chartResultInfo.label === '부서간 월별 데이터') return `${tickItem}월`;
    return `${tickItem}`;
  };

  const makeChartResult = (year, department) => {
    if (radioOptionValue === '연도별 총 데이터') {
      setChartResultInfo({
        label: '연도별 총 데이터',
        chartTitle: `${radioOptionValue}`,
      });
    }
    if (radioOptionValue === '부서간 월별 데이터') {
      setChartResultInfo({
        label: '부서간 월별 데이터',
        chartTitle: `${radioOptionValue} / 부서: ${department} / ${year}년`,
      });
    }
    if (radioOptionValue === '부서간 연도별 데이터') {
      setChartResultInfo({
        label: '부서간 연도별 데이터',
        chartTitle: `${radioOptionValue} / 부서: ${department}`,
      });
    }
  };

  return (
    <div className='flex items-center justify-center w-full h-full bg-gray-50'>
      <div className='w-full h-full p-4 p-10 bg-white rounded-xl '>
        <div className='flex gap-4 p-4'>
          <Radio.Group
            defaultValue='a'
            buttonStyle='solid'
            value={radioOptionValue}
            onChange={radioOnChange}
            options={RADIO_OPTIONS}
            optionType='button'
            size='large'
          />
        </div>
        {radioOptionValue && (
          <div className='flex gap-4 px-4 pb-4'>
            {!(radioOptionValue === '연도별 총 데이터') && (
              <Select
                showSearch
                size='large'
                placeholder='Select a Department'
                optionFilterProp='children'
                onChange={selectOnChange}
                onSearch={selectOnSearch}
                filterOption={filterOption}
                options={departmentList}
                style={{ width: '400px' }}
                value={department}
              />
            )}
            {radioOptionValue === '부서간 월별 데이터' && (
              <DatePicker
                onChange={datePickerOnChange}
                format={'YYYY'}
                size='large'
                picker='year'
              />
            )}
            <Button
              size='large'
              onClick={() => {
                차트만들기(year, department);
                makeChartResult(year, department);
              }}
              disabled={차트만들기Disabled()}>
              차트 만들기
            </Button>
          </div>
        )}
        <div className='flex flex-col items-center justify-center w-full h-4/5'>
          {chartData.length > 0 ? (
            <>
              <div className='w-full p-4 text-xl font-bold'>
                {chartResultInfo.chartTitle}
              </div>
              <ResponsiveContainer
                width={'100%'}
                height={'100%'}>
                <BarChart data={chartData}>
                  <XAxis
                    dataKey={
                      chartResultInfo.label === '부서간 월별 데이터'
                        ? 'month'
                        : 'year'
                    }
                    tickFormatter={formatXAxis}
                    domain={['auto', 'auto']}
                    interval={'preserveStart'}
                  />
                  <YAxis />
                  <Tooltip labelFormatter={formatXAxis} />
                  <Legend />
                  <Bar
                    dataKey='count'
                    fill='#43b6f4'
                  />
                </BarChart>
              </ResponsiveContainer>
            </>
          ) : (
            <div>데이터가 없습니다.</div>
          )}
        </div>
      </div>
    </div>
  );
};
